import React, { forwardRef } from "react";
import Icon from "../../_atoms/icons/icon/Icon";
import ApplePayMark from "../../../../assets/images/hnry_card/apple_pay_mark.svg";
import { handleManageCardClick } from "./helper";

export interface iAddToWalletButton {
  applePay?: boolean;
  googlePayMarkPath?: string;
}

const AddToWalletButton = forwardRef<HTMLButtonElement, iAddToWalletButton>(({
  applePay,
  googlePayMarkPath,
}, ref) =>  {
  const defaultButtonStyles = "tw-bg-transparent tw-text-gray-800";
  const applePayButtonStyles = "tw-w-full tw-bg-transparent tw-text-gray-800 tw-py-2 tw-px-2 border tw-border-gray-200 tw-rounded-lg";
  
  return (
    <div className="tw-pb-6 tw-px-4 tw-w-full">
      <div className="tw-flex tw-flex-row tw-items-center tw-pr-2">
        <button
          type={"button"}
          className={applePay ? applePayButtonStyles : defaultButtonStyles}
          onClick={handleManageCardClick}
          ref={ref}
        >
          {applePay &&
            <div className="tw-flex tw-flex-row tw-items-center tw-pr-2">
              <div className="tw-w-1/5 tw-px-1">
                <img src={ApplePayMark} alt={"Apple Pay Mark"} />
              </div>
              <div className="tw-flex tw-flex-1 tw-justify-between tw-ml-2">
                <div className="tw-flex tw-flex-col tw-items-start">
                  <span className="tw-text-sm tw-font-semibold">Add to Apple Pay</span>
                  <span className="tw-text-sm tw-font-light">Tap here to get started</span>
                </div>
                <div className="tw-py-2 tw-text-gray-800">
                  <Icon type="ChevronRightIcon" size="lg"></Icon>
                </div>
              </div>
            </div>
          }
          {!applePay &&
          <div className="tw-px-1">
            <img src={googlePayMarkPath} alt="Google Pay Mark" className="tw-object-cover tw-h-10" data-testid="googlePayMark" />
          </div>
          }
        </button>
      </div>
    </div>
  )});


AddToWalletButton.displayName = "Add to Wallet Button";

export default AddToWalletButton;
