import React from "react";
import MenuItem from "@hui/_molecules/dropdowns/menu/MenuItem";
import MoreActions from "@hui/_molecules/dropdowns/more_actions/MoreActions";
import redirectToExternalURL from "../../../utilities/redirect";
import { deleteUser } from "../../../API/users.api";
import I18n from "../../../utilities/translations";

interface iMoreActions { nativeApp: boolean }

const MenuActions = ({ nativeApp }: iMoreActions) => {
  const voidUser = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(I18n.t("home.uk_waitlist.confirm_leave_waitlist"))) {
      await deleteUser("VOID_LEAVE_WAITLIST");
      redirectToExternalURL(I18n.t("global.links.account_closure"));
    }
  };

  return (
    <div className="tw-mr-1">
      <MoreActions>
        {!nativeApp &&
        <MenuItem label={I18n.t("home.uk_waitlist.more_actions.sign_out")} href="/users/sign_out" />
        }
        <MenuItem label={I18n.t("home.uk_waitlist.more_actions.leave_waitlist")} asButton={true} onClick={voidUser} />
      </MoreActions>
    </div>
  );
};

export default MenuActions;