import React from "react";
import { useCardManagementCallback } from "./helper";
import AddToWalletButton from "./AddToWalletButton";

export interface iAddToWalletButtonWrapper {
  googlePayMarkPath?: string;
}

const AddToWalletButtonWrapper = ({googlePayMarkPath}: iAddToWalletButtonWrapper) => {
  const { alreadyInWallet, showApplePay, showGooglePay } = useCardManagementCallback();

  const renderText = () => {
    if (showApplePay) {
      return "Card available in Apple Wallet"
    } 
    if (showGooglePay) {
      return "Card available in Google Wallet"
    } 
    return "Card available in Wallet"
  }

  return (
    <div>
      {alreadyInWallet &&
      <div className="tw-pb-6 tw-px-4 tw-w-full">
        <div className="tw-text-sm tw-font-light tw-py-1">
          {renderText()}
        </div>
      </div>
      }
      {!alreadyInWallet && showApplePay &&
        <AddToWalletButton
          applePay={true}>
        </AddToWalletButton>
      }
      {!alreadyInWallet && showGooglePay &&
        <AddToWalletButton 
          applePay={false}
          googlePayMarkPath={googlePayMarkPath}>
        </AddToWalletButton>
      }
    </div>
  )
};

export default AddToWalletButtonWrapper;

