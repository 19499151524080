import React, { useState } from "react";
import Button from "@hui/_atoms/button/Button"
import LinkedModal from "./LinkedModal";
import RelinkBannerContent from "./RelinkBannerContent";
import { taxAgencyAuthorisation as taa, taxAgencyAuthorisationStates as states } from "../../types/taxAgencyAuthorisation.type";
import { relinkBanner } from "./translations";

const RelinkBanner = ({ taxAgencyAuthorisation, currentState }: { taxAgencyAuthorisation: taa, currentState: states }) => {
  const [linkedModalOpen, setLinkedModalOpen] = useState(false)

  const copy = relinkBanner[currentState]

  return (
    <>
      <div className="alert alert-new" role="alert" >
        <h2 className="hnry-heading hnry-heading--marketing-h1">{copy.title}</h2>
        <RelinkBannerContent currentState={currentState}/>
        <Button type="button" onClick={() => setLinkedModalOpen(true)}>
          {copy.button}
        </Button>
      </div>
      {linkedModalOpen && (
        <LinkedModal 
          linkedModalOpen={linkedModalOpen}
          setLinkedModalOpen={setLinkedModalOpen}
          taxAgencyAuthorisation={taxAgencyAuthorisation}
        />
      )}
    </>
  )
};

export default RelinkBanner
