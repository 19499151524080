import React, { useMemo } from "react";
import Icon from "../../_atoms/icons/icon/Icon";
import I18n from "../../../utilities/translations";
import { useCardManagementCallback } from "./helper";

interface iMoreActions {
  appleWalletLink?: string;
  googleWalletLink?: string;
  applicationSettingOff: boolean;
  platformIsAndroid: boolean;
  platformIsIos: boolean;
  onWithdrawFundsClick: () => void;
}

const MoreActions = ({
  appleWalletLink,
  googleWalletLink,
  applicationSettingOff,
  platformIsAndroid,
  platformIsIos,
  onWithdrawFundsClick,
}: iMoreActions) => {
  const { alreadyInWallet, showApplePay, showGooglePay } = useCardManagementCallback();
  const cardManagementCallback = window.CardManagementCallback;
  const walletLinkRequired = (applicationSettingOff && !platformIsAndroid && !platformIsIos) || !cardManagementCallback;

  const options = useMemo(
    () => [
      {
        title: I18n.t("home.hnry_card.card_statements.title"),
        id: "statements",
        description: I18n.t("home.hnry_card.card_statements.subtext"),
        url: Routes.reports_path(),
        target: false,
        eventName: "dashboard_card_more_reports_click",
      },
      {
        title: I18n.t("home.hnry_card.withdraw_funds.title"),
        id: "withdrawal",
        description: I18n.t("home.hnry_card.withdraw_funds.subtext"),
        onClick: () => onWithdrawFundsClick(),
        eventName: "dashboard_card_more_withdraw_funds_click",
      },
      {
        title: I18n.t("home.hnry_card.transactions.title"),
        id: "transactions",
        description: I18n.t("home.hnry_card.transactions.description"),
        url: Routes.cards_card_transactions_path(),
        target: false,
        eventName: "dashboard_card_more_transactions_click",
      },
      ...((appleWalletLink && !alreadyInWallet && showApplePay) || (platformIsIos && !alreadyInWallet && applicationSettingOff) || walletLinkRequired
        ? [
          {
            title: I18n.t("home.hnry_card.apple_wallet.title"),
            id: "add-apple-wallet",
            url: appleWalletLink,
            target: true,
            eventName: "dashboard_card_more_apple_wallet_click",
          },
        ]
        : []),
      ...((googleWalletLink && !alreadyInWallet && showGooglePay) || (platformIsAndroid && !alreadyInWallet && applicationSettingOff) || walletLinkRequired
        ? [
          {
            title: I18n.t("home.hnry_card.google_wallet.title"),
            id: "add-google-wallet",
            url: googleWalletLink,
            target: true,
            eventName: "dashboard_card_more_google_wallet_click",
          },
        ]
        : []),
    ],
    [alreadyInWallet, appleWalletLink, applicationSettingOff, cardManagementCallback, googleWalletLink, onWithdrawFundsClick, platformIsAndroid, platformIsIos, showApplePay, showGooglePay],
  );

  return (
    <ul className="tw-divide-y tw-divide-gray-100 tw-mb-0">
      {options.map(({
        title, id, description, url, onClick, target, eventName
      }) => (
        <li className="hover:tw-bg-gray-50" key={`card-more-action-${id}`}>
          {onClick ? (
            <button
              onClick={onClick}
              className="tw-flex tw-justify-between tw-gap-x-6 tw-py-2 tw-items-center tw-w-full tw-text-left"
              data-track-click={JSON.stringify({ eventName })}
            >
              <div className="tw-flex tw-min-w-0 tw-gap-x-4">
                <div className="tw-min-w-0 tw-flex-auto tw-text-gray-900">
                  <p className="tw-font-semibold tw-mb-0">
                    {title}
                  </p>
                  {description && <p className="tw-text-xs tw-font-normal tw-mb-0">{description}</p>}
                </div>
              </div>
              <div className="tw-flex tw-items-center">
                <Icon type="ChevronRightIcon" size="base" />
              </div>
            </button>) : (
            <a
                href={url}
                data-track-click={JSON.stringify({ eventName })}
                className="tw-flex tw-justify-between tw-gap-x-6 tw-py-2 tw-items-center"
                target={target ? "_blank" : undefined}
                rel={target ? "noreferrer" : undefined}
            >
                <div className="tw-flex tw-min-w-0 tw-gap-x-4">
                <div className="tw-min-w-0 tw-flex-auto tw-text-gray-900">
                    <p className="tw-font-semibold tw-mb-0">
                    {title}
                  </p>
                    {description && <p className="tw-text-xs tw-font-normal tw-mb-0">{description}</p>}
                  </div>
              </div>
                <div className="tw-flex tw-items-center">
                <Icon type="ChevronRightIcon" size="base" />
              </div>
              </a>
          )}
        </li>
      ))}
    </ul>
  );
};

export default MoreActions;

