import { useState, useEffect } from "react";

export const useCardManagementCallback = () => {
  const cardManagementCallback = window.CardManagementCallback;
  const [alreadyInWallet, setAlreadyInWallet] = useState(false);
  const [showApplePay, setShowApplePay] = useState(false);
  const [showGooglePay, setShowGooglePay] = useState(false);

  useEffect(() => {
    if (cardManagementCallback) { 
      const isAlreadyInWallet = cardManagementCallback.alreadyInWallet?.();
      const shouldShowApplePay = cardManagementCallback.shouldShowApplePay?.();
      const shouldShowGooglePay = cardManagementCallback.shouldShowGooglePay?.();

      if (isAlreadyInWallet) {
        setAlreadyInWallet(true);
      }
      if (shouldShowApplePay) {
        setShowApplePay(true);
      } else if (shouldShowGooglePay) {
        setShowGooglePay(true);
      }
    }
  }, [cardManagementCallback]);

  return { alreadyInWallet, showApplePay, showGooglePay };
}

export const handleManageCardClick = () => {
  const cardManagementCallback = window.CardManagementCallback;
  if (cardManagementCallback) {
    cardManagementCallback.manageCardClicked();
  }
}
