import React, { useState } from "react";
import I18n from "../../utilities/translations";

import Icon from "../_atoms/icons/icon/Icon";
import Modal from "../_molecules/modal/Modal";
import { withdrawFunds } from "../../API/cards.api";
import WithdrawFunds from "./card/WithdrawFunds";
import MoreActions from "./card/MoreActions";

export interface iCardMoreActions {
  appleWalletLink: string;
  availableBalance: number;
  googleWalletLink: string;
  platformIsAndroid: boolean;
  platformIsIos: boolean;
  applicationSettingOff: boolean;
  canWithdraw: boolean;
  jurisdiction: string;
}

const MAIN_SCREEN = "more_actions";
const WITHDRAW_SCREEN = "withdraw_funds";

const ctx = { scope: "home.hnry_card" };

const CardMoreActions = ({
  googleWalletLink,
  appleWalletLink,
  platformIsAndroid,
  platformIsIos,
  applicationSettingOff,
  availableBalance,
  canWithdraw,
  jurisdiction,
}: iCardMoreActions) => {
  const moreActionsTitle = I18n.t("more_actions.title", ctx)
  
  const [showModal, setShowModal] = useState(false);
  const [screen, setScreen] = useState(MAIN_SCREEN);
  const [modalTitle, setModalTitle] = useState(moreActionsTitle);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const handleScreenChange = (sc) => {
    setScreen(sc);
    setModalTitle(sc === WITHDRAW_SCREEN ? I18n.t("withdraw_funds.title", ctx) : moreActionsTitle);
  };

  const handleClick = () => {
    setShowModal(!showModal);
    handleScreenChange(MAIN_SCREEN);
  };

  const handleWithdrawFundsClick = () => {
    handleScreenChange(WITHDRAW_SCREEN);
    setDisabledSubmit(true);
  };

  const reset = () => {
    setShowModal(false);
    setModalTitle(moreActionsTitle);
    setWithdrawAmount("");
    setScreen(MAIN_SCREEN);
    setDisabledSubmit(false);
  };

  const handleWithdrawSubmit = async () => {
    try {
      setDisabledSubmit(true);
      const response = await withdrawFunds({ amount: withdrawAmount });

      if (response.status === "bad_request") {
        toastr.error(response.message);
      } else if (response.status === "ok") {
        toastr.success(response.message);
      } else {
        toastr.success(I18n.t("cards.manage_funds.withdraw_funds.error"));
      }
    } catch (error) {
      if (typeof Rollbar !== "undefined") {
        Rollbar.warning("User unable to withdraw funds from card", { error });
      }
    } finally {
      reset();
    }
  };

  return (
    <>
      <button className="hnry-button hnry-button--link" onClick={handleClick}>
        <Icon type="EllipsisHorizontalIcon" classes="!tw-mx-0" />
        <span className="tw-block tw-text-xs tw-pt-2">More</span>
      </button>
      {showModal && (
        <Modal
          onConfirm={screen === WITHDRAW_SCREEN ? handleWithdrawSubmit : null}
          open={showModal}
          setOpen={setShowModal}
          title={modalTitle}
          closable
          disabled={disabledSubmit}
          onCancel={reset}
        >
          {screen === WITHDRAW_SCREEN
            ? <WithdrawFunds
                availableBalance={availableBalance}
                withdrawAmount={withdrawAmount}
                setWithdrawAmount={setWithdrawAmount}
                setDisabledSubmit={setDisabledSubmit}
                canWithdraw={canWithdraw}
                jurisdiction={jurisdiction}
            />
            : <MoreActions
              appleWalletLink={appleWalletLink}
              googleWalletLink={googleWalletLink}
              platformIsAndroid={platformIsAndroid} 
              platformIsIos={platformIsIos}
              applicationSettingOff={applicationSettingOff}
              onWithdrawFundsClick={handleWithdrawFundsClick}/>
          }
        </Modal>
      )}
    </>
  );
};

export default CardMoreActions;
