import React from "react";
import Icon from "../_atoms/icons/icon/Icon";
import CardMoreActions, { iCardMoreActions } from "./CardMoreActions";
import CardDetails from "./active_card_module/CardDetails";
import { postJson } from "../../API/config/fetch.api";
import CardAddFunds, { iCardAddFunds } from "./CardAddFunds";
import I18n from "../../utilities/translations";

export interface iCardModuleFooter extends iCardAddFunds, iCardMoreActions {
  cardBlocked: boolean;
  intercomLink: string;
  otpRequiredForLogin: boolean;
  setCardFrozen: (cardBlocked) => void;
  userEmail: string;
  userPhoneNumber: string;
  canWithdraw: boolean;
  jurisdiction: string;
}

const CardModuleFooter = ({
  googleWalletLink,
  appleWalletLink,
  platformIsAndroid,
  platformIsIos,
  applicationSettingOff,
  availableBalance,
  otpRequiredForLogin,
  userEmail,
  userPhoneNumber,
  cardBlocked,
  setCardFrozen,
  intercomLink,
  hnryBsb,
  hnryAccountNumber,
  canWithdraw,
  allocationFrequencyOptions,
  allocationTemplate,
  allocation,
  isImpersonating,
  maxAllocationPercentage,
  jurisdiction,
}: iCardModuleFooter) => {
  const handleFreezeCard = () => {
    const cardAction = cardBlocked ? "unblock" : "block";

    postJson(Routes.update_card_cards_path(), JSON.stringify({ card_action: cardAction })).then(() => {
      toastr.success(`Card ${cardAction === "unblock" ? "un-frozen. Any Hnry Card allocations that we paused for you have now been resumed." : "frozen. All Hnry Card allocations have been paused."}`);
      setCardFrozen(!cardBlocked);
    }).catch(() => {
      toastr.error(`Failed to ${cardAction === "unblock" ? "un-" : ""}freeze card. Please try again`);
    });
  };

  return (
    <>{!cardBlocked
        && <div className="tw-flex tw-justify-evenly tw-mx-2 tw-w-full">
          <CardAddFunds
            hnryBsb={hnryBsb}
            hnryAccountNumber={hnryAccountNumber}
            allocationFrequencyOptions={allocationFrequencyOptions}
            allocationTemplate={allocationTemplate}
            allocation={allocation}
            isImpersonating={isImpersonating}
            maxAllocationPercentage={maxAllocationPercentage} />
          <CardDetails
            otpRequiredForLogin={otpRequiredForLogin}
            userPhoneNumber={userPhoneNumber}
            userEmail={userEmail}
            jurisdiction={jurisdiction} />
          <button className="hnry-button hnry-button--link" onClick={handleFreezeCard}>
            <Icon type="SnowFlakeIcon" classes="!tw-mx-0" />
            <span className="tw-block tw-text-xs tw-pt-2">{"Freeze"}</span>
          </button>
          <CardMoreActions
            googleWalletLink={googleWalletLink}
            appleWalletLink={appleWalletLink}
            platformIsAndroid={platformIsAndroid}
            platformIsIos={platformIsIos}
            applicationSettingOff={applicationSettingOff}
            availableBalance={availableBalance}
            canWithdraw={canWithdraw}
            jurisdiction={jurisdiction} />
        </div>
    }
      {cardBlocked
        && <div className="tw-flex tw-justify-evenly tw-mx-2 tw-w-full">
          <a className="hnry-button hnry-button--link tw-block" href={intercomLink}>
            <Icon type="TrashIcon" classes="!tw-mx-0" />
            <span className="tw-block tw-text-xs tw-pt-2">Cancel card</span>
          </a>
          <button className="hnry-button hnry-button--link" onClick={handleFreezeCard}>
            <Icon type="SnowFlakeIcon" classes="!tw-mx-0" />
            <span className="tw-block tw-text-xs tw-pt-2">{"Un-freeze"}</span>
          </button>
        </div>
    }
      {cardBlocked
        && <div className="p-3"><div className="tw-bg-brand-50 tw-rounded-md tw-px-4 tw-py-3 -tw-mx-4 -tw-mb-4 tw-text-xs tw-text-gray-700 tw-text-center">
          {I18n.t("cards.block_card.frozen_card_information_banner")}</div>
        </div>
    }
    </>
  );
};

export default CardModuleFooter;
