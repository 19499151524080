import React, { useState } from "react";
import Button from "@hui/_atoms/button/Button";
import ConsentCheckbox from "@hui/_molecules/consent_checkbox/ConsentCheckbox";
import Modal from "@hui/_molecules/modal/Modal";
import I18n from "../../utilities/translations";
import { delinkScreens } from "./types";

interface iSalesTaxOnlyConsentModal {
  salesTaxOnlyConsentModalOpen: boolean;
  setFlowState: (string: delinkScreens) => void;
  setSalesTaxOnlyConsentModalOpen: (boolean) => void;
}

const translationOptions = {
  scope: "home.call_to_action.delinked.sales_tax_only_consent",
  tax_agency: I18n.t("global.tax_collection_authority_short"),
  sales_tax: I18n.t("global.sales_tax")
}

const consentName = "sales_tax_linked_limits_acknowledged"

const SalesTaxOnlyConsentModal = ({ salesTaxOnlyConsentModalOpen, setFlowState, setSalesTaxOnlyConsentModalOpen }: iSalesTaxOnlyConsentModal) => {
  const [consentAccepted, setConsentAccepted] = useState(false)
  const handleSubmit = () => {
    setSalesTaxOnlyConsentModalOpen(false);
    setFlowState("sales_tax_linked")
  };

  const handleBack = () => {
    setSalesTaxOnlyConsentModalOpen(false)
    setFlowState("sales_tax_proceed")
  }

  return (
    <Modal
      closable={true}
      open={salesTaxOnlyConsentModalOpen}
      setOpen={setSalesTaxOnlyConsentModalOpen}
      title={I18n.t("title", translationOptions)}
      includesFooter={false}
    >
      <div className="list-disc tw-prose-sm tw-prose-grey tw-pb-2" dangerouslySetInnerHTML={{ __html: I18n.t("content_html", translationOptions) }} />
      <ConsentCheckbox
        name={consentName}
        onChange={setConsentAccepted}
        label={I18n.t("consent_label", translationOptions)}
        id={consentName}
      />
      <div className="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row-reverse tw-justify-between mt-1 hnry-dialog-panel-actions">
        <Button variant="primary" disabled={!consentAccepted} onClick={handleSubmit}>{I18n.t("button", translationOptions)}</Button>
        <Button variant="secondary" onClick={handleBack}>Back</Button>
      </div>
    </Modal >
  )
}

export default SalesTaxOnlyConsentModal
